import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a724dea = () => interopDefault(import('../pages/comment/index.vue' /* webpackChunkName: "pages/comment/index" */))
const _501eefce = () => interopDefault(import('../pages/commodity/index.vue' /* webpackChunkName: "pages/commodity/index" */))
const _1df92d5f = () => interopDefault(import('../pages/etbh/index.vue' /* webpackChunkName: "pages/etbh/index" */))
const _9e1126ee = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _fe5c1456 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _59cc8a61 = () => interopDefault(import('../pages/noviceHelp/index.vue' /* webpackChunkName: "pages/noviceHelp/index" */))
const _a6653d7e = () => interopDefault(import('../pages/policyview/index.vue' /* webpackChunkName: "pages/policyview/index" */))
const _12101f0e = () => interopDefault(import('../pages/qualifications/index.vue' /* webpackChunkName: "pages/qualifications/index" */))
const _aa08c35c = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _5520e1e0 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _06123e12 = () => interopDefault(import('../pages/shoppingCart/index.vue' /* webpackChunkName: "pages/shoppingCart/index" */))
const _376f6e77 = () => interopDefault(import('../pages/transverse/index.vue' /* webpackChunkName: "pages/transverse/index" */))
const _5655db34 = () => interopDefault(import('../pages/brand/brandList.vue' /* webpackChunkName: "pages/brand/brandList" */))
const _3a07182c = () => interopDefault(import('../pages/brand/brandProductsList.vue' /* webpackChunkName: "pages/brand/brandProductsList" */))
const _a67734a2 = () => interopDefault(import('../pages/home/404.vue' /* webpackChunkName: "pages/home/404" */))
const _45f29d18 = () => interopDefault(import('../pages/home/store.vue' /* webpackChunkName: "pages/home/store" */))
const _494d1f34 = () => interopDefault(import('../pages/login/bindPhone.vue' /* webpackChunkName: "pages/login/bindPhone" */))
const _64a386b9 = () => interopDefault(import('../pages/search/searchNo.vue' /* webpackChunkName: "pages/search/searchNo" */))
const _5cfc5670 = () => interopDefault(import('../pages/shop/storeQualification.vue' /* webpackChunkName: "pages/shop/storeQualification" */))
const _1edcfe8e = () => interopDefault(import('../pages/shoppingCart/order.vue' /* webpackChunkName: "pages/shoppingCart/order" */))
const _5d8b4346 = () => interopDefault(import('../pages/shoppingCart/payment.vue' /* webpackChunkName: "pages/shoppingCart/payment" */))
const _0051bbe2 = () => interopDefault(import('../pages/shoppingCart/paymentSelect.vue' /* webpackChunkName: "pages/shoppingCart/paymentSelect" */))
const _09c6a4ab = () => interopDefault(import('../pages/shoppingCart/paySuccess.vue' /* webpackChunkName: "pages/shoppingCart/paySuccess" */))
const _b17628d2 = () => interopDefault(import('../pages/transverse/articleDetails.vue' /* webpackChunkName: "pages/transverse/articleDetails" */))
const _01f80d99 = () => interopDefault(import('../pages/transverse/articleList.vue' /* webpackChunkName: "pages/transverse/articleList" */))
const _9d7b4598 = () => interopDefault(import('../pages/transverse/articleMain.vue' /* webpackChunkName: "pages/transverse/articleMain" */))
const _6d1b173e = () => interopDefault(import('../pages/transverse/goodShop.vue' /* webpackChunkName: "pages/transverse/goodShop" */))
const _b0bb5f68 = () => interopDefault(import('../pages/user/baseInfo.vue' /* webpackChunkName: "pages/user/baseInfo" */))
const _4f2ddfb0 = () => interopDefault(import('../pages/user/browseRecord.vue' /* webpackChunkName: "pages/user/browseRecord" */))
const _2cbf5adf = () => interopDefault(import('../pages/user/messageCenter.vue' /* webpackChunkName: "pages/user/messageCenter" */))
const _545157cb = () => interopDefault(import('../pages/user/myAddress.vue' /* webpackChunkName: "pages/user/myAddress" */))
const _31c38297 = () => interopDefault(import('../pages/user/myCollection.vue' /* webpackChunkName: "pages/user/myCollection" */))
const _54cafb41 = () => interopDefault(import('../pages/user/orderDetails.vue' /* webpackChunkName: "pages/user/orderDetails" */))
const _9bf100c0 = () => interopDefault(import('../pages/user/orderPage.vue' /* webpackChunkName: "pages/user/orderPage" */))
const _dfd2f0fe = () => interopDefault(import('../pages/user/orderReturn.vue' /* webpackChunkName: "pages/user/orderReturn" */))
const _38d5a2b4 = () => interopDefault(import('../pages/user/unsubscribe.vue' /* webpackChunkName: "pages/user/unsubscribe" */))
const _72b031bb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/comment",
    component: _1a724dea,
    name: "comment"
  }, {
    path: "/commodity",
    component: _501eefce,
    name: "commodity"
  }, {
    path: "/etbh",
    component: _1df92d5f,
    name: "etbh"
  }, {
    path: "/home",
    component: _9e1126ee,
    name: "home"
  }, {
    path: "/login",
    component: _fe5c1456,
    name: "login"
  }, {
    path: "/noviceHelp",
    component: _59cc8a61,
    name: "noviceHelp"
  }, {
    path: "/policyview",
    component: _a6653d7e,
    name: "policyview"
  }, {
    path: "/qualifications",
    component: _12101f0e,
    name: "qualifications"
  }, {
    path: "/search",
    component: _aa08c35c,
    name: "search"
  }, {
    path: "/shop",
    component: _5520e1e0,
    name: "shop"
  }, {
    path: "/shoppingCart",
    component: _06123e12,
    name: "shoppingCart"
  }, {
    path: "/transverse",
    component: _376f6e77,
    name: "transverse"
  }, {
    path: "/brand/brandList",
    component: _5655db34,
    name: "brand-brandList"
  }, {
    path: "/brand/brandProductsList",
    component: _3a07182c,
    name: "brand-brandProductsList"
  }, {
    path: "/home/404",
    component: _a67734a2,
    name: "home-404"
  }, {
    path: "/home/store",
    component: _45f29d18,
    name: "home-store"
  }, {
    path: "/login/bindPhone",
    component: _494d1f34,
    name: "login-bindPhone"
  }, {
    path: "/search/searchNo",
    component: _64a386b9,
    name: "search-searchNo"
  }, {
    path: "/shop/storeQualification",
    component: _5cfc5670,
    name: "shop-storeQualification"
  }, {
    path: "/shoppingCart/order",
    component: _1edcfe8e,
    name: "shoppingCart-order"
  }, {
    path: "/shoppingCart/payment",
    component: _5d8b4346,
    name: "shoppingCart-payment"
  }, {
    path: "/shoppingCart/paymentSelect",
    component: _0051bbe2,
    name: "shoppingCart-paymentSelect"
  }, {
    path: "/shoppingCart/paySuccess",
    component: _09c6a4ab,
    name: "shoppingCart-paySuccess"
  }, {
    path: "/transverse/articleDetails",
    component: _b17628d2,
    name: "transverse-articleDetails"
  }, {
    path: "/transverse/articleList",
    component: _01f80d99,
    name: "transverse-articleList"
  }, {
    path: "/transverse/articleMain",
    component: _9d7b4598,
    name: "transverse-articleMain"
  }, {
    path: "/transverse/goodShop",
    component: _6d1b173e,
    name: "transverse-goodShop"
  }, {
    path: "/user/baseInfo",
    component: _b0bb5f68,
    name: "user-baseInfo"
  }, {
    path: "/user/browseRecord",
    component: _4f2ddfb0,
    name: "user-browseRecord"
  }, {
    path: "/user/messageCenter",
    component: _2cbf5adf,
    name: "user-messageCenter"
  }, {
    path: "/user/myAddress",
    component: _545157cb,
    name: "user-myAddress"
  }, {
    path: "/user/myCollection",
    component: _31c38297,
    name: "user-myCollection"
  }, {
    path: "/user/orderDetails",
    component: _54cafb41,
    name: "user-orderDetails"
  }, {
    path: "/user/orderPage",
    component: _9bf100c0,
    name: "user-orderPage"
  }, {
    path: "/user/orderReturn",
    component: _dfd2f0fe,
    name: "user-orderReturn"
  }, {
    path: "/user/unsubscribe",
    component: _38d5a2b4,
    name: "user-unsubscribe"
  }, {
    path: "/",
    component: _72b031bb,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
