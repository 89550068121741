import request from '~/utils/request';

// 用户信息查询
export function getUserLoginId(data) {
  return request.http(
    'get', '/user/pc/baseInfo', data, 0, {}
  );
}

// 我的收藏
export function getCollectionInfo(data) {
  return request.http(
    'post', '/product/pharmacy/info', data, 1, {}
  );
}

// 取消收藏
export function getCollectionDelete(data) {
  return request.http(
    'post', '/product/pharmacy/delete', data, 1, {}
  );
}
// 浏览记录
export function getBrowseRecordList(data) {
  return request.http(
    'get', '/product/pharmacy/browseRecordList', data, 0, {}
  );
}
// 删除浏览记录
export function browseRecordDelete(data) {
  return request.http(
    'post', '/product/pharmacy/delBrowseRecord', data, 1, {}
  );
}

// 修改用户接收短信
export function changeUserReceiveType(data) {
  return request.http(
    'post', '/user/msg/receive', data, 1, {}
  );
}
// 修改用户接收短信
export function getUserReceiveType(data) {
  return request.http(
    'get', '/user/party/getUserLoginId', data, 1, {}
  );
}
// 是否可以注销账号
export function isUnsubscribe(data) {
  return request.http(
    'get', '/user/closed/try', data, 1, {}
  );
}
// 发送验证码
export function sendCode(data) {
  return request.http(
    'post', '/user/validCode', data, 1, {}
  );
}

// 注销账号
export function unsubscribe(data) {
  return request.http(
    'post', '/user/closed/commit', data, 1, {}
  );
}

// 个人中心-消息中心-相关 -- start
// 站内未读信息统计
export function getNotReadMessageCount(data) {
  return request.http(
    'get', '/user/messageUserRead/getNotReadMessageCount', data, 0, {}
  );
}

// 用户/商家查看所有消息列表
export function getAllMessageListData(data) {
  return request.http(
    'get', '/user/messageUserRead/getAllMessage', data, 0, {}
  );
}

// 站内消息详情-并更新已读标记
export function getDetailMarkRead(data) {
  return request.http(
    'get', '/user/stationMessage/getDetail', data, 0, {}
  );
}
// 个人中心-消息中心-相关 -- end